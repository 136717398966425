import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Typography, TextField, Box } from "@material-ui/core";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";
import { Autocomplete } from "@material-ui/lab";
import AllKampoData from "data/all-kampo.json";
import OTCKampoData from "data/otc-kampo.json";
import MaterialTable from "material-table";
import TableIcons from "views/components/TableIcons";

function ReleventSearch() {
  const params = new URLSearchParams(useLocation().search);
  const kampo = params.get("q");
  const [selectedKampo, setSelectedKampo] = useState();
  const [releventOTCKampo, setReleventOTCKampo] = useState();
  const [searched, setSearched] = useState();
  const resetStates = () => {
    setSelectedKampo();
    setReleventOTCKampo();
  };
  const handleChange = (_, data) => {
    const selectedKampoName = data?.name;
    if (selectedKampoName) {
      window.location.href = `/relevent-search?q=${selectedKampoName}`;
    } else {
      resetStates();
    }
  };
  const search = (selectedKampoName) => {
    const selectedKampoData = AllKampoData.find(
      (item) => item.name === selectedKampoName
    );
    if (!selectedKampoData) return;
    setSelectedKampo(selectedKampoData);
    let matchedKampo = [].concat(_.cloneDeep(OTCKampoData)).map((item) => {
      item.count = 0;
      return item;
    });
    selectedKampoData.set.split(",").forEach((a) => {
      matchedKampo.forEach((b, i) => {
        if (b.set.split(",").includes(a)) {
          matchedKampo[i].count++;
          matchedKampo[i].set = matchedKampo[i].set.replace(a, `<b>${a}</b>`);
        }
      });
    });
    matchedKampo = matchedKampo
      .filter((item) => item.count > 0)
      .sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
      });
    setReleventOTCKampo(matchedKampo);
  };
  if (kampo && !searched) {
    setSearched(true);
    search(kampo);
  }

  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <Typography paragraph>類似漢方検索</Typography>
        <Autocomplete
          autoSelect
          options={AllKampoData}
          getOptionLabel={(option) => `${option.name}`}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label="漢方方剤" variant="outlined" />
          )}
          defaultValue={selectedKampo}
        />
        {selectedKampo && (
          <>
            <Box mb={2} />
            <Typography paragraph>
              使用されている生薬:{" "}
              <b>{selectedKampo.set.split(",").join(", ")}</b>
            </Typography>
            <Box md={3} />
            <MaterialTable
              icons={TableIcons}
              columns={[
                { title: "一致生薬数", field: "count" },
                { title: "処方番号", field: "id" },
                { title: "名前", field: "name" },
                {
                  title: "生薬",
                  field: "set",
                  render: (rowData) => {
                    return (
                      <div dangerouslySetInnerHTML={{ __html: rowData.set }} />
                    );
                  },
                },
              ]}
              data={releventOTCKampo}
              title="関連一般用漢方製剤製造販売承認基準"
              localization={{
                toolbar: {
                  searchTooltip: "検索",
                  searchPlaceholder: "検索",
                },
                pagination: {
                  labelRowsSelect: "件",
                  labelDisplayedRows: "{from}-{to} / {count}",
                },
              }}
            />
          </>
        )}
      </div>
    </ResponsiveDrawer>
  );
}

export default ReleventSearch;
