import React from "react";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";
import MaterialTable from "material-table";
import OTCKampoData from "data/otc-kampo.json";
import TableIcons from "views/components/TableIcons";

function OTCKampo() {
  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <MaterialTable
          icons={TableIcons}
          columns={[
            { title: "処方番号", field: "id" },
            { title: "名前", field: "name" },
            { title: "生薬", field: "set" },
          ]}
          data={OTCKampoData}
          title="一般用漢方製剤製造販売承認基準"
          localization={{
            toolbar: {
              searchTooltip: "検索",
              searchPlaceholder: "検索",
            },
            pagination: {
              labelRowsSelect: "件",
              labelDisplayedRows: "{from}-{to} / {count}",
            },
          }}
        />
      </div>
    </ResponsiveDrawer>
  );
}

export default OTCKampo;
