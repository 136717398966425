import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import combineReducers from "./combineReducers";
import app, { appState } from "./ducks/app";
import user, { userState } from "./ducks/user";

const rootReducer = combineReducers({
  app,
  user,
});

const initialState = {
  app: appState,
  user: userState,
};

const StoreContext = createContext(initialState);

export const useStore = () => {
  const { state, dispatch } = useContext(StoreContext);
  return { state, dispatch };
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
