import React from "react";
import { Typography } from "@material-ui/core";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";

function Links() {
  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <Typography paragraph>関連リンク</Typography>
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.mhlw.go.jp/seisakunitsuite/bunya/kenkou_iryou/iyakuhin/topics/dl/tp150401-01-02.pdf"
              target="_blank"
            >
              薬局製剤指針 - 厚生労働省
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.mhlw.go.jp/file/06-Seisakujouhou-11120000-Iyakushokuhinkyoku/0000160072.pdf"
              target="_blank"
            >
              一般用漢方製剤製造販売承認基準 - 厚生労働省
            </a>
          </li>
        </ul>
      </div>
    </ResponsiveDrawer>
  );
}

export default Links;
