/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:45951b4e-552f-41d2-8acc-92e1199f8a8b",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_khZnuVEwT",
    "aws_user_pools_web_client_id": "4rfdposc1euco42rve2oclq62o",
    "oauth": {
        "domain": "kamposeb7f6d87d-b7f6d87d-prod.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://kampose.com/",
        "redirectSignOut": "http://localhost:3000/,https://kampose.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
