import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: "10px",
  },
}));

function FooterCommit() {
  const classes = useStyles();
  if (process.env.REACT_APP_AWS_BRANCH !== "master")
    return (
      <Box className={classes.root}>{process.env.REACT_APP_AWS_COMMIT_ID}</Box>
    );
  return <></>;
}

export default FooterCommit;
