import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "views/containers/Home";
import Links from "views/containers/Links";
import ReleventSearch from "views/containers/ReleventSearch";
import ComponentSearch from "views/containers/ComponentSearch";
import ReproduceSearch from "views/containers/ReproduceSearch";
import OTCKampo from "views/containers/OTCKampo";
import AllKampo from "views/containers/AllKampo";
import AllSyoyaku from "views/containers/AllSyoyaku";
import Privacy from "views/containers/Privacy";
import Terms from "views/containers/Terms";
import FooterCommit from "views/components/FooterCommit";
import { Auth } from "aws-amplify";
import { useStore } from "state/store";

function App() {
  const { dispatch } = useStore();

  useEffect(() => {
    async function getUser() {
      const user = await Auth.currentUserInfo();
      if (user) {
        dispatch({
          type: "@user/signIn",
          user,
        });
      }
      return user;
    }

    getUser();
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/reproduce-search">
          <ReproduceSearch />
        </Route>
        <Route exact path="/relevent-search">
          <ReleventSearch />
        </Route>
        <Route exact path="/component-search">
          <ComponentSearch />
        </Route>
        <Route exact path="/otc-kampo">
          <OTCKampo />
        </Route>
        <Route exact path="/all-kampo">
          <AllKampo />
        </Route>
        <Route exact path="/all-syoyaku">
          <AllSyoyaku />
        </Route>
        <Route exact path="/links">
          <Links />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route>
          <>404</>
        </Route>
      </Switch>
      <FooterCommit />
    </>
  );
}

export default App;
