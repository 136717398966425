import React, { useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Autocomplete } from "@material-ui/lab";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";
import AllKampoData from "data/all-kampo.json";
import OTCKampoData from "data/otc-kampo.json";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.count}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>処方番号</TableCell>
                    <TableCell>名前</TableCell>
                    <TableCell>生薬</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.data.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        <div dangerouslySetInnerHTML={{ __html: item.set }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ComponentSearch() {
  const params = new URLSearchParams(useLocation().search);
  const kampo = params.get("q");
  const [selectedKampo, setSelectedKampo] = useState();
  const [reproduceOTCKampo, setReproduceOTCKampo] = useState();
  const [searched, setSearched] = useState();
  const resetStates = () => {
    setSelectedKampo();
    setReproduceOTCKampo();
  };
  const handleChange = (_, data) => {
    const selectedKampoName = data?.name;
    if (selectedKampoName) {
      window.location.href = `/component-search?q=${selectedKampoName}`;
    } else {
      resetStates();
    }
  };
  const search = (selectedKampoName) => {
    const selectedKampoData = AllKampoData.find(
      (item) => item.name === selectedKampoName
    );
    if (!selectedKampoData) return;
    setSelectedKampo(selectedKampoData);
    let matchedKampo = [];
    selectedKampoData.set.split(",").forEach((a, i) => {
      matchedKampo[i] = {
        name: a,
        count: 0,
        data: [],
      };
      OTCKampoData.forEach((b) => {
        if (b.set.split(",").includes(a)) {
          const copiedB = _.cloneDeep(b);
          copiedB.set = copiedB.set.replace(a, `<b>${a}</b>`);
          matchedKampo[i].count++;
          matchedKampo[i].data.push(copiedB);
        }
      });
    });
    matchedKampo = matchedKampo.sort((a, b) => {
      if (a.count > b.count) return -1;
      if (a.count < b.count) return 1;
      return 0;
    });
    setReproduceOTCKampo(matchedKampo);
  };
  if (kampo && !searched) {
    setSearched(true);
    search(kampo);
  }
  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <Typography paragraph>構成生薬利用漢方検索</Typography>
        <Autocomplete
          autoSelect
          options={AllKampoData}
          getOptionLabel={(option) => `${option.name}`}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label="漢方方剤" variant="outlined" />
          )}
          defaultValue={selectedKampo}
        />
        {selectedKampo && (
          <>
            <Box mb={2} />
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>生薬名</TableCell>
                    <TableCell>該当生薬使用漢方数</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reproduceOTCKampo.map((row) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </ResponsiveDrawer>
  );
}

export default ComponentSearch;
