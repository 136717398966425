import React from "react";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";
import MaterialTable from "material-table";
import AllKampoData from "data/all-kampo.json";
import TableIcons from "views/components/TableIcons";

function AllKampo() {
  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <MaterialTable
          icons={TableIcons}
          columns={[
            { title: "名前", field: "name" },
            { title: "生薬", field: "set" },
          ]}
          data={AllKampoData}
          title="漢方方剤"
          localization={{
            toolbar: {
              searchTooltip: "検索",
              searchPlaceholder: "検索",
            },
            pagination: {
              labelRowsSelect: "件",
              labelDisplayedRows: "{from}-{to} / {count}",
            },
          }}
        />
      </div>
    </ResponsiveDrawer>
  );
}

export default AllKampo;
