import React from "react";
import { Typography } from "@material-ui/core";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";

function Home() {
  return (
    <ResponsiveDrawer>
      <Typography paragraph>
        漢方に関する様々な検索とデータを提供するウェブサービスです
      </Typography>
    </ResponsiveDrawer>
  );
}

export default Home;
