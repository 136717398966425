export const appState = {
  draweOpen: false,
};

export default (state = appState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
