import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useStore } from "state/store";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ResponsiveDrawer(props) {
  const { children } = props;
  const { pathname } = useLocation();
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { state } = useStore();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClick = (to) => {
    if (to === pathname) {
      return;
    }
    setRedirectTo(to);
  };

  if (redirectTo) return <Redirect push to={redirectTo} />;

  const drawer = (
    <>
      <List>
        <ListItem
          button
          selected={pathname === "/"}
          onClick={() => {
            handleClick("/");
          }}
        >
          <ListItemText primary="ホーム" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader>検索</ListSubheader>
        <ListItem
          button
          selected={pathname === "/reproduce-search"}
          onClick={() => {
            handleClick("/reproduce-search");
          }}
        >
          <ListItemText primary="組み合わせ再現漢方検索" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/component-search"}
          onClick={() => {
            handleClick("/component-search");
          }}
        >
          <ListItemText primary="構成生薬利用漢方検索" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/relevent-search"}
          onClick={() => {
            handleClick("/relevent-search");
          }}
        >
          <ListItemText primary="類似漢方検索" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader>データ</ListSubheader>
        <ListItem
          button
          selected={pathname === "/otc-kampo"}
          onClick={() => {
            handleClick("/otc-kampo");
          }}
        >
          <ListItemText primary="一般用漢方製剤製造販売承認基準" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/all-kampo"}
          onClick={() => {
            handleClick("/all-kampo");
          }}
        >
          <ListItemText primary="漢方方剤" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/all-syoyaku"}
          onClick={() => {
            handleClick("/all-syoyaku");
          }}
        >
          <ListItemText primary="生薬" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          selected={pathname === "/terms"}
          onClick={() => {
            handleClick("/terms");
          }}
        >
          <ListItemText primary="利用規約" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/privacy"}
          onClick={() => {
            handleClick("/privacy");
          }}
        >
          <ListItemText primary="プライバシーポリシー" />
        </ListItem>
        <ListItem
          button
          selected={pathname === "/links"}
          onClick={() => {
            handleClick("/links");
          }}
        >
          <ListItemText primary="関連リンク" />
        </ListItem>
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            カンポウズ
          </Typography>
          {/*state.user.isSignIn ? (
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => Auth.signOut()}
            >
              ログアウト
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => Auth.federatedSignIn()}
            >
              ログイン
            </Button>
          )*/}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ResponsiveDrawer;
