export const userState = {
  isSignIn: false,
  data: {},
};

export default (state = userState, action) => {
  switch (action.type) {
    case "@user/signIn": {
      return {
        isSignIn: true,
        data: action.user,
      };
    }
    case "@user/signOut": {
      return {
        isSignIn: false,
        data: {},
      };
    }

    default: {
      return state;
    }
  }
};
