import React, { useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Autocomplete } from "@material-ui/lab";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";
import AllKampoData from "data/all-kampo.json";
import OTCKampoData from "data/otc-kampo.json";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.length > 1 ? `${row[0].name} + ${row[1].name}` : row[0].name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>処方番号</TableCell>
                    <TableCell>名前</TableCell>
                    <TableCell>生薬</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        <div dangerouslySetInnerHTML={{ __html: item.set }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ReproduceSearch() {
  const params = new URLSearchParams(useLocation().search);
  const kampo = params.get("q");
  const [selectedKampo, setSelectedKampo] = useState();
  const [reproduceOTCKampo, setReproduceOTCKampo] = useState();
  const [searched, setSearched] = useState();
  const resetStates = () => {
    setSelectedKampo();
    setReproduceOTCKampo();
  };
  const handleChange = (_, data) => {
    const selectedKampoName = data?.name;
    if (selectedKampoName) {
      window.location.href = `/reproduce-search?q=${selectedKampoName}`;
    } else {
      resetStates();
    }
  };
  const search = (selectedKampoName) => {
    const selectedKampoData = AllKampoData.find(
      (item) => item.name === selectedKampoName
    );
    if (!selectedKampoData) return;
    setSelectedKampo(selectedKampoData);
    const exactKampoData = OTCKampoData.find(
      (item) => item.name === selectedKampoName
    );
    if (exactKampoData) {
      setReproduceOTCKampo([[exactKampoData]]);
      return;
    }
    const targetSyoyaku = selectedKampoData.set.split(",");
    const pairs = [];
    const hashTable = [];
    OTCKampoData.forEach((OTCKampo) => {
      const inputSyoyaku = OTCKampo.set.split(",");
      const diff = _.difference(targetSyoyaku, inputSyoyaku);
      const foundItems = [];
      hashTable.forEach((item, index) => {
        if (_.difference(diff, item).length === 0) {
          foundItems.push(index);
        }
      });
      if (foundItems.length > 0) {
        foundItems.forEach((index) => {
          const copiedBaseKampo = Object.assign({}, OTCKampo);
          const copiedMatchedKampo = Object.assign({}, OTCKampoData[index]);
          copiedBaseKampo.set = copiedBaseKampo.set
            .split(",")
            .map((item) => {
              if (targetSyoyaku.includes(item)) return `<b>${item}</b>`;
              return item;
            })
            .join(", ");
          copiedMatchedKampo.set = copiedMatchedKampo.set
            .split(",")
            .map((item) => {
              if (targetSyoyaku.includes(item)) return `<b>${item}</b>`;
              return item;
            })
            .join(", ");
          pairs.push([copiedMatchedKampo, copiedBaseKampo]);
        });
      }
      hashTable.push(inputSyoyaku);
    });
    pairs.sort((a, b) => {
      if (a[0].id > b[0].id) return -1;
      if (a[0].id < b[0].id) return 1;
      return 0;
    });
    setReproduceOTCKampo(pairs);
  };
  if (kampo && !searched) {
    setSearched(true);
    search(kampo);
  }
  return (
    <ResponsiveDrawer>
      <div style={{ maxWidth: "calc(100vw - 48px)" }}>
        <Typography paragraph>組み合わせ再現漢方検索</Typography>
        <Autocomplete
          autoSelect
          options={AllKampoData}
          getOptionLabel={(option) => `${option.name}`}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label="漢方方剤" variant="outlined" />
          )}
          defaultValue={selectedKampo}
        />
        {selectedKampo && (
          <>
            <Box mb={2} />
            <Typography paragraph>
              使用されている生薬:{" "}
              <b>{selectedKampo.set.split(",").join(", ")}</b>
            </Typography>
            <Box md={3} />
            {reproduceOTCKampo.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>組み合わせ漢方名</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reproduceOTCKampo.map((row, index) => (
                      <Row key={`match-${index}`} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography paragraph>組み合わせが見つかりません。</Typography>
            )}
          </>
        )}
      </div>
    </ResponsiveDrawer>
  );
}

export default ReproduceSearch;
