import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "state/store";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "views/style/theme";
import App from "views/App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

// Refs: https://github.com/aws-amplify/amplify-cli/issues/2792
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

// Refs: https://github.com/aws-amplify/amplify-cli/issues/1880
awsconfig.oauth.domain = "auth.kampose.com";

Amplify.configure(awsconfig);

if (process.env.REACT_APP_AWS_BRANCH !== "master") {
  window["ga-disable-GA_TRACKING_ID"] = true;
}

function Kampose() {
  const router = useRef(null);

  useEffect(() => {
    router.current.history.listen(() => {
      window.gtag("config", process.env.REACT_APP_GTAG, {
        page_path: window.location.pathname,
        page_location: window.location.href,
      });
    });
  });

  return (
    <StoreProvider>
      <BrowserRouter ref={router}>
        <>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </>
      </BrowserRouter>
    </StoreProvider>
  );
}

ReactDOM.render(<Kampose />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
