import React from "react";
import { Typography } from "@material-ui/core";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";

function Privacy() {
  return (
    <ResponsiveDrawer>
      <Typography paragraph>プライバシーポリシー</Typography>
      現在、ユーザーのデータは取得していません。
    </ResponsiveDrawer>
  );
}

export default Privacy;
