import React from "react";
import { Typography } from "@material-ui/core";
import ResponsiveDrawer from "views/components/ResponsiveDrawer";

function Terms() {
  return (
    <ResponsiveDrawer>
      <Typography paragraph>利用規約</Typography>
      ユーザーは、本サービス（当社が提供するその他のサービス及び提携サービスを含みます。以下本条において同様です。）の利用にあたり、自ら又は第三者をして以下の各号のいずれかに該当する行為をしてはなりません。<br />
(1) 法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為及びこれらを助長する行為又はそのおそれのある行為<br />
(2) 当社又は他のユーザーその他の第三者に対する詐欺又は脅迫行為<br />
(3) 当社又は他のユーザーその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為又はそのおそれのある行為<br />
(4) 他のユーザーの承諾なく、他のユーザーの本製品を動作できる又は動作できない状態にする行為<br />
(5) リバースエンジニアリング<br />
(6) 本サービス又は当社の業務を妨害することを目的に本サービスのネットワーク又はシステム等に過度な負荷をかける行為<br />
(7) 本サービスに接続しているシステム全般に権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為その他当社に損害を与える行為<br />
(8) コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報を送信する行為<br />
(9) スクレイピングなど、情報の収集を目的とする行為<br />
(10) 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為<br />
(11) 反社会的勢力等への利益供与行為<br />
(12) 本サービス利用契約及び本サービスの趣旨・目的に反する行為<br />
(13) 前各号の行為を直接又は間接に惹起し、又は容易にする行為<br />
(14) その他、当社が不適切と判断する行為<br />
    </ResponsiveDrawer>
  );
}

export default Terms;
